import React from 'react';
import { formatPhoneNumber, formatPhoneNumberWithoutCountryCode } from '../utils/formatUtils'; // Import necessary utilities

import img1 from '../assets/images/android-call-fwd-1.jpg';
import img2 from '../assets/images/android-call-fwd-2.jpeg';
import img3 from '../assets/images/android-call-fwd-3.jpeg';
import img4 from '../assets/images/android-call-fwd-4.jpeg';
import img5 from '../assets/images/android-call-fwd-5.jpeg';
import img6 from '../assets/images/android-call-fwd-6.jpg';
import img7 from '../assets/images/android-call-fwd-7.jpg';

const VerizonInstructions = (assistantPhoneNumberClean) => (
  <div>
    <p>Finally, grab your phone and dial/call the number below (including *) to forward missed calls to your assistant:</p>
    <div className="font-mono bg-gray-200 p-2 my-2 rounded">*71{assistantPhoneNumberClean}</div>
    <p className="text-sm mt-2">This sends calls to your assistant when you're: busy, don't answer, or are unreachable. If you have any issues, please send an email to <a href="mailto:jack@ringaling.ai" className="text-blue-600 hover:text-blue-800">jack@ringaling.ai</a></p>
  </div>
);

const IPhoneATTInstructions = (assistantPhoneNumberClean) => (
  <div>
    <p>Finally, grab your iPhone and dial/call the number below (including *'s and #'s) to forward missed calls to your assistant:</p>
    <div className="font-mono bg-gray-200 p-2 my-2 rounded">*004*1{assistantPhoneNumberClean}*11#</div>
    <p className="text-sm mt-2">This sends calls to your assistant when you're: busy, don't answer, or are unreachable. If you have any issues, please send an email to <a href="mailto:jack@ringaling.ai" className="text-blue-600 hover:text-blue-800">jack@ringaling.ai</a></p>
  </div>
);

const AndroidInstructions = (assistantPhoneNumberClean) => (
  <div>
    <p>Finally, grab your Android, go to your Phone app, and follow the instructions below. In the final step, you should enter your assistant phone number which is: <span className="text-red-500">{formatPhoneNumber(assistantPhoneNumberClean) || 'Not yet assigned'}</span>.</p>
    <div className="flex flex-wrap justify-center gap-5">
      <img src={img1} alt="Android Call Forwarding Step 1" className="min-w-[200px] w-full sm:w-1/2 md:w-1/3 lg:w-1/4"/>
      <img src={img2} alt="Android Call Forwarding Step 2" className="min-w-[200px] w-full sm:w-1/2 md:w-1/3 lg:w-1/4"/>
      <img src={img3} alt="Android Call Forwarding Step 3" className="min-w-[200px] w-full sm:w-1/2 md:w-1/3 lg:w-1/4"/>
      <img src={img4} alt="Android Call Forwarding Step 4" className="min-w-[200px] w-full sm:w-1/2 md:w-1/3 lg:w-1/4"/>
      <img src={img5} alt="Android Call Forwarding Step 5" className="min-w-[200px] w-full sm:w-1/2 md:w-1/3 lg:w-1/4"/>
      <img src={img6} alt="Android Call Forwarding Step 6" className="min-w-[200px] w-full sm:w-1/2 md:w-1/3 lg:w-1/4"/>
      <img src={img7} alt="Android Call Forwarding Step 7" className="min-w-[200px] w-full sm:w-1/2 md:w-1/3 lg:w-1/4"/>
    </div>
    <p className="text-sm mt-2">This sends calls to your assistant when you're: busy, don't answer, or are unreachable. If you have any issues, please send an email to <a href="mailto:jack@ringaling.ai" className="text-blue-600 hover:text-blue-800">jack@ringaling.ai</a></p>
  </div>
);

const getInstructions = (assistantPhoneNumber) => {
  const assistantPhoneNumberClean = formatPhoneNumberWithoutCountryCode(assistantPhoneNumber);

  return {
    "iPhone-Verizon": VerizonInstructions(assistantPhoneNumberClean),
    "Landline-Verizon": VerizonInstructions(assistantPhoneNumberClean),
    "iPhone-AT&T": IPhoneATTInstructions(assistantPhoneNumberClean),
    "iPhone-T-Mobile": IPhoneATTInstructions(assistantPhoneNumberClean),
    "Android-AT&T": AndroidInstructions(assistantPhoneNumberClean),
    "Android-T-Mobile": AndroidInstructions(assistantPhoneNumberClean),
    "Android-Verizon": AndroidInstructions(assistantPhoneNumberClean),
    "Landline-AT&T": (
      <div>
        <p>Finally, grab your AT&T Landline and dial/call the two numbers below (including *'s and #'s) to forward missed calls to your assistant:</p>
        <div className="font-mono bg-gray-200 p-2 my-2 rounded">*921{assistantPhoneNumberClean}#</div>
        <div className="font-mono bg-gray-200 p-2 my-2 rounded">*901{assistantPhoneNumberClean}#</div>
        <p className="text-sm mt-2">This sends calls to your assistant when you're: busy or don't answer. If you have any issues, please send an email to <a href="mailto:jack@ringaling.ai" className="text-blue-600 hover:text-blue-800">jack@ringaling.ai</a></p>
      </div>
    ),
    "Landline-T-Mobile": (
      <div>
        <p>Finally, grab your T-Mobile Landline and dial/call the number below (including *'s and #'s) to forward missed calls to your assistant:</p>
        <div className="font-mono bg-gray-200 p-2 my-2 rounded">**67*1{assistantPhoneNumberClean}*11#</div>
        <div className="font-mono bg-gray-200 p-2 my-2 rounded">**62*1{assistantPhoneNumberClean}*11#</div>
        <div className="font-mono bg-gray-200 p-2 my-2 rounded">**61*1{assistantPhoneNumberClean}*11#</div>
        <p className="text-sm mt-2">This sends calls to your assistant when you're: busy, don't answer, or are unreachable. If you have any issues, please send an email to <a href="mailto:jack@ringaling.ai" className="text-blue-600 hover:text-blue-800">jack@ringaling.ai</a></p>
      </div>
    )
  };
};

export default getInstructions;

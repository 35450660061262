// src/components/onboarding/UserSettings.js
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { updateDoc, getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';

const UserSettings = () => {
  const { user, updateOnboardingStatus } = useGlobalContext();
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [phoneCarrier, setPhoneCarrier] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [timezone, setTimezone] = useState('');

  // Fetch user's existing data when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.uid) {
        const userRef = doc(db, 'Users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setCompanyName(data.companyName || '');
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setPhoneNumber(data.phoneNumber || '');
          setPhoneType(data.phoneType || '');
          setPhoneCarrier(data.phoneCarrier || '');
          setTimezone(data.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
          setAgreedToTerms(data.agreedToTerms || false);
        }
      }
    };

    fetchUserData();
  }, [user]);

  // Existing useEffect for setting timezone if not fetched from Firestore
  useEffect(() => {
    if (!timezone) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimezone(tz);
    }
  }, [timezone]);

  const validateAndProceed = async () => {

    setIsLoading(true);

    if (!companyName || !firstName || !lastName || !phoneNumber || !phoneType || !phoneCarrier) {
      setIsLoading(false);
      toast.error('Please fill in all fields');
      return;
    }

    if (!agreedToTerms) {  // Check if terms are agreed
      toast.error('You must agree to the Terms of Service and Privacy Policy to proceed.');
      setIsLoading(false);
      return;
    }
    
    const phoneNumberPattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneNumber.match(phoneNumberPattern)) {
      setIsLoading(false);
      toast.error('Please enter a valid phone number in the format: (555) 555-5555');
      return;
    }

    // Update user details in Firestore
    const userRef = doc(db, 'Users', user.uid);
    try {
      await updateDoc(userRef, { companyName, firstName, lastName, phoneNumber, phoneType, phoneCarrier, timezone });

      await updateOnboardingStatus(2); // IN MAY 23

    } catch (error) {
      setIsLoading(false);
      console.error('Error updating user settings:', error);
      toast.error('Failed to save user settings. Please try again.');
    }
  };

  // Utility function to format phone number
  const formatInputPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
        <div className="md:flex">
          <div className="w-full p-5">
            <h2 className="text-2xl font-semibold text-gray-700 text-center">User Settings</h2>
            
            {/* Company Name */}
            <div className="text-sm font-bold text-gray-700 mt-4">Company Name:</div>
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
            />

            {/* First Name */}
            <div className="text-sm font-bold text-gray-700 mt-4">First Name:</div>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
            />

            {/* Last Name */}
            <div className="text-sm font-bold text-gray-700 mt-4">Last Name:</div>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
            />

            {/* Phone Type */}
            <div className="text-sm font-bold text-gray-700 mt-4">Phone Type:</div>
            <select
              name="phoneType"
              value={phoneType}
              onChange={(e) => setPhoneType(e.target.value)}
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="">Select...</option>
              <option value="iPhone">iPhone</option>
              <option value="Android">Android</option>
              <option value="Landline">Landline</option>
            </select>

            {/* Phone Carrier */}
            <div className="text-sm font-bold text-gray-700 mt-4">Phone Carrier:</div>
            <select
              name="phoneCarrier"
              value={phoneCarrier}
              onChange={(e) => setPhoneCarrier(e.target.value)}
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="">Select...</option>
              <option value="AT&T">AT&T</option>
              <option value="T-Mobile">T-Mobile</option>
              <option value="Verizon">Verizon</option>
            </select>

            {/* Phone Number */}
            <div className="text-sm font-bold text-gray-700 mt-4">Phone Number:</div>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(formatInputPhoneNumber(e.target.value))}
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
            />

            {/* Terms */}
            <div className="mt-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-gray-600"
                /><span className="ml-2 text-gray-700">
                  I have read, understood, and accepted the <Link to="/terms" className="text-blue-600 hover:underline">Terms of Service </Link>
                  and <Link to="/privacy" className="text-blue-600 hover:underline">Privacy Policy</Link>.
                </span>
              </label>
            </div>
            <button
              onClick={validateAndProceed}
              disabled={isLoading}
              className={`w-full mt-6 ${isLoading ? 'bg-gray-500' : 'bg-green-800 hover:bg-green-900'} text-white font-semibold py-3 px-4 rounded-md`}
            >
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                'Next'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;